import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { getNotification } from '../Service/Api';
import Slider from "react-slick";
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const Notification = () => {
  const [data, setData] = useState([]);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    const getData = async () => {
      const datas = await getNotification();
      console.log(datas);
      setData(datas);
      if (datas.length > 0) {
        setShowModal(true);
      }
    };
    getData();
  }, []);

  useEffect(() => {
    const modalElement = document.getElementById("exampleModalToggle");
    let modalInstance;

    if (modalElement && showModal) {
      // Initialize modal using Bootstrap's JavaScript
      modalInstance = new window.bootstrap.Modal(modalElement);
      modalInstance.show();

      // Add event listener for modal hide event
      modalElement.addEventListener("hidden.bs.modal", handleModalHidden);
    }

    return () => {
      if (modalElement) {
        modalElement.removeEventListener("hidden.bs.modal", handleModalHidden);
      }
      if (modalInstance) {
        modalInstance.hide();
      }
    };
  }, [showModal]);

  const handleModalHidden = () => {
    setShowModal(false);
  };

  const settings = {
    autoplay: true,
    infinite: true,
    autoplaySpeed: 2000,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    arrows: false,
    adaptiveHeight: true,
    responsive: [
      {
        breakpoint: 1300,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <>
      {data?.length > 0 && (
        <div
          className="popup modal fade"
          id="exampleModalToggle"
          aria-labelledby="exampleModalToggleLabel"
          tabIndex="-1"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div id="modalH" className="slide pop-slider">
                <Slider {...settings}>
                  {data.map((item, index) => (
                    <div key={index} className="item">
                      
                      <div className="modal-body">
                        <div className="card modalcard">
                        {/* <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      >
                        <i className="bi bi-x-lg"></i>
                      </button> */}
                          <div className="row">
                            <div className="card-body">
                              <h1>{item.title}</h1>
                              <img
                                src={`https://webapi.entab.info/api/image/${item.image[0]}`}
                                className="img-fluid"
                                alt="Notification"
                              />
                              <p>{item.description}</p>
                              <div className="clearfix"></div>
                              <div className="modal-bottom">
                                <Link
                                  target="_blank"
                                  to={`https://webapi.entab.info/api/image/${item.attachments[0]}`}
                                  className="btn btn-primary btn-sm"
                                >
                                  Attachment
                                </Link>
                                <Link
                                  target="_blank"
                                  to={item?.url}
                                  className="btn btn-primary btn-sm"
                                >
                                  Click Here
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </Slider>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Notification;
